import { createRouter, createWebHashHistory } from "vue-router";
import Login from "./modules/auth/auth/Login.vue";
import App from "./modules/layout/layout/App/";

const routes = [
  { path: "/", redirect: { name: "login" } },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/admin",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./modules/layout/Dashboard.vue"),
      },
      {
        path: "/employee",
        name: "employeeList",
        component: () => import("./modules/core/components/Employee/EmployeeList"),
      },
      {
        path: "/employeecrud",
        name: "employeeCrud",
        component: () =>
          import("./modules/core/components/Employee/EmployeeCrud"),
      },
      {
        path: "/employeecrud/:id",
        name: "employeeCrudEdit",
        component: () =>
          import("./modules/core/components/Employee/EmployeeCrud"),
      },
      {
        path: "/charge",
        name: "charge",
        component: () => import("./modules/core/components/Catalog/Charge"),
      },
      {
        path: "/country",
        name: "country",
        component: () => import("./modules/core/components/Catalog/Country"),
      },
      {
        path: "/organizations",
        name: "organizations",
        component: () => import("./modules/core/components/Catalog/Organization"),
      },
      {
        path: "/payment",
        name: "payment",
        component: () => import("./modules/core/components/Catalog/Payments"),
      },
      {
        path: "/cliquidations",
        name: "cliquidations",
        component: () =>
          import("./modules/core/components/Catalog/Cliquidations"),
      },
      {
        path: "/origin",
        name: "origin",
        component: () => import("./modules/core/components/Catalog/Origin"),
      },
      {
        path: "/city",
        name: "city",
        component: () => import("./modules/core/components/Catalog/City"),
      },
      {
        path: "/filial",
        name: "filial",
        component: () => import("./modules/core/components/Catalog/Filial"),
      },
      {
        path: "/company",
        name: "company",
        component: () => import("./modules/core/components/Catalog/Companies"),
      },
      {
        path: "/month",
        name: "month",
        component: () => import("./modules/core/components/Catalog/Month"),
      },
      {
        path: "/week",
        name: "week",
        component: () => import("./modules/core/components/Catalog/Week"),
      },
      {
        path: "/plans",
        name: "plans",
        component: () => import("./modules/core/components/Catalog/Plans"),
      },
      {
        path: "/contract/:id",
        name: "contracts",
        component: () => import("./modules/core/components/Catalog/Plans"),
      },
      {
        path: "/log",
        name: "log",
        component: () => import("./modules/core/components/Catalog/SystemLog"),
      },
      {
        path: "/month-liquidations",
        name: "MonthLiquidation",
        component: () => import("./modules/core/components/Catalog/MonthLiquidation"),
      },
      {
        path: "/week-liquidations",
        name: "WeekLiquidation",
        component: () => import("./modules/core/components/Catalog/WeekLiquidation"),
      },
      {
        path: "/campus",
        name: "campus",
        component: () => import("./modules/core/components/Catalog/Campus"),
      },
      {
        path: "/zones",
        name: "Zone",
        component: () => import("./modules/core/components/Catalog/Zone"),
      },
    ],
  },
  {
    path: "/commission",
    component: App,
    children: [
      {
        path: "processed",
        name: "commissionList",
        component: () => import("./modules/core/components/Commission/CommissionList"),
      },
      {
        path: "detail/:id",
        name: "commissionDetail",
        component: () => import("./modules/core/components/Commission/CommissionDetail"),
      },
    ],
  },
  {
    path: "/liquidation",
    component: App,
    children: [
      {
        path: "list",
        name: "liquidationList",
        component: () => import("./modules/core/components/Liquidation/LiquidationList"),
      },
      {
        path: "validate",
        name: "validationList",
        component: () => import("./modules/core/components/Contralor/ValidationList"),
      },
      {
        path: "validate/detail/:id",
        name: "validationDetail",
        component: () => import("./modules/core/components/Contralor/ValidationDetail"),
      },
      {
        path: "detail/:id",
        name: "liquidationDetail",
        component: () => import("./modules/core/components/Liquidation/LiquidationDetail"),
      },
      {
        path: "view/:id",
        name: "liquidationDetailView",
        component: () => import("./modules/core/components/Liquidation/LiquidationDetailView"),
      },
      {
        path: "reset",
        name: "liquidationReset",
        component: () => import("./modules/core/components/Liquidation/LiquidationReset"),
      },
    ],
  },
  {
    path: "/verification",
    component: App,
    children: [
      {
        path: "holders",
        name: "holderList",
        component: () => import("./modules/enrollment/components/holders/HolderList.vue"),
      },
      {
        path: "enrollment/:id",
        name: "enrollment",
        component: () =>
          import("./modules/enrollment/components/enrollments/EnrollmentList.vue"),
        props: { holder: {} },
      },
      {
        path: "check",
        name: "check",
        component: () => import("./modules/enrollment/components/payments/PaymentList.vue"),
        props: { holder: {} },
      },
      {
        path: "net-payment",
        name: "netPayment",
        component: () => import("./modules/core/components/Liquidation/NetPayment.vue"),
        props: { holder: {} },
      },
    ],
  },
  {
    path: "/report",
    name: "Reportes",
    component: App,
    children: [
      {
        path: "core",
        name: "CoreReport",
        component: () => import("./modules/reports/components/CoreReport"),
      },
      {
        path: "enroll",
        name: "EnrollReport",
        component: () => import("./modules/reports/components/EnrollReport"),
      },
      {
        path: "enroll/monthly",
        name: "EnrollMonthlyReport",
        component: () => import("./modules/reports/components/EnrollMonthlyReport"),
      },
      {
        path: "verification",
        name: "VerificationReport",
        component: () => import("./modules/reports/components/VerificationReport"),
      },
      {
        path: "verification/ok-detallado",
        name: "VerificationOkDetalladoReport",
        component: () => import("./modules/reports/components/VerificationOkDetalladoReport"),
      },
      {
        path: "resume-commission",
        name: "ResumeCommissionReport",
        component: () => import("./modules/reports/components/ResumeCommissionReport"),
      },
      {
        path: "percentage-plans",
        name: "PercentagePlansReport",
        component: () => import("./modules/reports/components/PercentagePlansReport"),
      },
      {
        path: "resume-payments",
        name: "ResumePaymentReport",
        component: () => import("./modules/reports/components/ResumePaymentReport"),
      },
      {
        path: "assignment-for-country",
        name: "ResumeMonthCloseReport",
        component: () => import("./modules/reports/components/ResumeMonthCloseReport"),
      },
      {
        path: "students",
        name: "StudentsReport",
        component: () => import("./modules/reports/components/StudentsReport"),
      },
      {
        path: "informe-verify",
        name: "InfVerifyReport",
        component: () => import("./modules/reports/components/InfVerifyReport"),
      },
      {
        path: "consolidate-enrollment",
        name: "EnrollConsolidateReport",
        component: () => import("./modules/reports/components/EnrollConsolidateReport"),
      },
    ],
  },
  {
    path: "/my-pays",
    name: "Mis Pagos",
    component: App,
    children: [
      {
        path: "liquidations",
        name: "IndexLiquidationsMyPays",
        component: () => import("./modules/core/components/MyPays/Liquidations/Index"),
      },
      {
        path: "liquidations/detail/:id",
        name: "BodyLiquidationsMyPays",
        component: () => import("./modules/core/components/MyPays/Liquidations/Body"),
      },
    ],
  },
  {
    path: "/raise-money",
    component: App,
    children: [
      {
        path: "list",
        name: "RaiseMoneyList",
        component: () => import("./modules/core/components/RaiseMoney/List.vue"),
      },
      {
        path: "my-enrollments",
        name: "MyEnrollmentsList",
        component: () => import("./modules/core/components/RaiseMoney/MyEnrollmentsList/"),
      },
      {
        path: "detail/:id/:enrollment",
        name: "RaiseMoneyDetail",
        component: () => import("./modules/core/components/RaiseMoney/Detail.vue"),
        props: { enrollment: {} },
      },
      {
        path: "detail-payments/:id/:enrollment",
        name: "RaiseMoneyDetailPayment",
        component: () => import("./modules/core/components/RaiseMoney/DetailPayment/"),
        props: { enrollment: {} },
      },
      {
        path: "detail-payments-finish/:id/:enrollment",
        name: "RaiseMoneyDetailPaymentFinish",
        component: () =>
          import("./modules/core/components/RaiseMoney/DetailPaymentFinish.vue"),
        props: { enrollment: {} },
      },
      {
        path: "holders",
        name: "RaiseMoneyHolderList",
        component: () => import("./modules/core/components/RaiseMoney/HolderList.vue"),
      },
      {
        path: "enrollments/:id",
        name: "HolderEnrollmentList",
        component: () =>
          import("./modules/core/components/RaiseMoney/HolderEnrollmentList.vue"),
      },
      {
        path: "monitor",
        name: "RaiseMoneyMonitor",
        component: () => import("./modules/core/components/RaiseMoney/Monitor.vue"),
      },
      {
        path: "payments-finish",
        name: "PaymentFinishList",
        component: () => import("./modules/core/components/RaiseMoney/PaymentFinishList.vue"),
      },
    ],
  },
  {
    path: "/import",
    component: App,

    children: [
      {
        path: "fix",
        name: "fixImport",
        component: () => import("./modules/core/components/FixImport/fixImport"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory("/"),
  routes,
});

export default router;
